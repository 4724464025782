import React, { FC, SyntheticEvent, useCallback, useContext } from "react";
import classnames from "classnames";
import { ISportFeedResponse } from "@finbackoffice/websocket-client";
import { Feed } from "@finbackoffice/site-core";
import { useRouter } from "next/router";
import { PrematchContext } from "contexts";
import { FeedTypeEnum, useGetCurrentFeedType, useSocketStorage } from "hooks";
import SportIcon from "components/base/sport-icon/SportIcon";
import styles from "./simple-sidebar.module.sass";

type Props = {
    sports: Feed;
    setSelectedSport: (val: ISportFeedResponse) => void;
    selectedSportId: number | null;
    setArrowPos: (val: number) => void;
};

const SportsList: FC<Props> = ({ sports, setSelectedSport, selectedSportId, setArrowPos }) => {
    const router = useRouter();
    const { getSportBySlug } = useSocketStorage(FeedTypeEnum.live);
    const { tournaments } = useContext(PrematchContext);
    const feed = useGetCurrentFeedType();

    const isSelected = useCallback(
        (sportId: number) =>
            feed === FeedTypeEnum.prematch
                ? !!tournaments.find((item) => sportId === item.sportId)
                : getSportBySlug(router.query.liveGameRoutes?.[0] as string)?.id === sportId,
        [feed, getSportBySlug, router.query.liveGameRoutes, tournaments],
    );

    const handleClick = useCallback(
        (e: SyntheticEvent, sport: ISportFeedResponse) => {
            setSelectedSport(sport);
            setArrowPos(e.currentTarget.getBoundingClientRect().top);
        },
        [setArrowPos, setSelectedSport],
    );

    return (
        <div>
            <ul className={styles.sportsList}>
                {sports.map((sport: ISportFeedResponse) => (
                    <li
                        key={sport.id}
                        onClick={(e) => handleClick(e, sport)}
                        className={classnames(
                            selectedSportId === sport.id && styles.opened,
                            isSelected(sport.id) && styles.selected,
                        )}>
                        <div>
                            <SportIcon sportId={sport.id} />
                            <span className={styles.count}>{sport.games_count}</span>
                        </div>
                        <span className={styles.sportName} title={sport.name || ""}>
                            {sport.name}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SportsList;
