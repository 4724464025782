import { FC } from "react";
import { ISiteConfigLayouts, SportsbookLayoutEnum } from "@finbackoffice/fe-core";
import { useSiteConfig } from "@finbackoffice/site-core";
import { Svg } from "../svg/Svg";

type IProps = {
    sportId: number;
};

const SportIcon: FC<IProps> = ({ sportId }) => {
    const sportsbookConfig = useSiteConfig<ISiteConfigLayouts>("layouts").sportsbook;
    const iconsFolder =
        sportsbookConfig.type === SportsbookLayoutEnum.Flat ? "sport-icons-flat" : "sport-icons";
    return (
        <Svg
            src={`/common/${iconsFolder}/${sportId}.svg`}
            wrapper="span"
            className="svg-sport-icon"
            loader
        />
    );
};

export default SportIcon;
